import React, { Component } from 'react';
import Helmet from 'react-helmet';

import SlideMailM from '../SlideMailM';
import SlideDating from '../SlideDating';
import SlideHoroscopes from '../SlideHoroscopes';
import SlideAlice from '../SlideAlice';
import AppDownloadModal from '../AppDownloadModal';

import config from '../../config';

import s from './apps.scss';
import sAlice from '../SlideAlice/index.scss';


export class LinkWithModal extends Component {
  constructor() {
    super();
    this.state = {
      isModalOpened: false,
    };
    this.handleAppClick = this.handleAppClick.bind(this);
  }

  handleAppClick() {
    this.setState({
      isModalOpened: true,
    });
  }
  
  render() {
    return(
      <React.Fragment>
        <a
          onClick={this.handleAppClick}
          href={this.props.href}
          className={this.props.className}
          data-soft={this.props.dataSoft}
        />
        <AppDownloadModal
          isOpen={this.state.isModalOpened}
          onClose={() => {
            this.setState({
              isModalOpened: false,
            });
          }}
        />
      </React.Fragment>
    );
  }
}

export class AppMailM extends Component {
  render() {
    const title = 'Рамблер/почта – ' + config.siteTitle;
    const description =
      'Официальное бесплатное приложение Рамблер/почты <nobr>с возможностью</nobr> сбора ' +
      'писем <nobr>с других</nobr> ящиков и встроенным списком задач';
    return (
      <div className={s.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className={s.slide}>
          <SlideMailM stat={{ place: 'top' }}>
            <br />
            <a
              href={'/mobile'}
              className={s.back}
              data-soft="product::mail_m::back"
            >
              <span className={s.t}>К списку приложений</span>
            </a>
          </SlideMailM>
        </div>
        <div className={s.advantages}>
          {[
            {
              id: 'security',
              title: 'Просто и безопасно',
              description:
                'Вход <nobr>в приложение</nobr> можно защитить пин-кодом или отпечатком вашего пальца',
            },
            {
              id: 'repeat',
              title: 'Второй шанс есть всегда',
              description:
                'Ошиблись <nobr>с адресатом</nobr>? <nobr>В приложении</nobr> Рамблер/почта можно вернуть назад <nobr>уже отправленное</nobr> письмо',
            },
            {
              id: 'folder',
              title: 'Не только Рамблер',
              description:
                'Читайте <nobr>в одном</nobr> приложении письма <nobr>из всех</nobr> своих почтовых ящиков: Яндекс, Gmail и других',
            },
            {
              id: 'mobile',
              title: 'Понятный каждому',
              description:
                'Простой и чистый интерфейс делает Рамблер/почту удобней многих встроенных <nobr>в смартфоны</nobr> клиентов',
            },
            {
              id: 'turbo',
              title: 'Для медленного интернета',
              description:
                'Рамблер/почта работает даже на очень медленном Интернете, а читать письма можно в оффлайне',
            },
          ].map(item => (
            <dl
              key={item.title}
              className={s.advantages_item + ' ' + s['item_' + item.id]}
            >
              <dt
                className={s.advantages_item_title}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <dd
                className={s.advantages_item_description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </dl>
          ))}
        </div>
        <div className={s.install}>
          <a
            href={
              'https://redirect.appmetrica.yandex.com/serve/532764277224855368'
            }
            className={s.button_ru_store}
            target="_blank"
            data-soft={'product::ramblerapp::button_2::rustore'}
          />
          <div id="LinkWithModal_mailm2">
            <LinkWithModal
              href="https://redirect.appmetrica.yandex.com/serve/965110089922992189"
              className={s.button_google_app}
              dataSoft={'product::mail_m::button_2::download'}
            />
          </div>
        </div>
        <div className={s.self_promo}>
          <div className={s.self_promo_content}>
            <div className={s.self_promo_text}>
              <div className={s.self_promo_title}>
                Рамблер/почта — <br />
                надежный и удобный<br />
                почтовый ящик
              </div>
              <div className={s.self_promo_description}>
                Доступ к почте на Рамблере и письмам <nobr>с других</nobr>
                <br />
                сервисов через удобное расширение <nobr>в вашем</nobr>
                <br />
                браузере.
              </div>
              <a
                href="https://mail.rambler.ru/?utm_source=soft&utm_medium=link&utm_campaign=self_promo&utm_content=mail"
                targe="_blank"
                className={s.button}
                data-soft="product::mail_m::button_3"
              >
                Завести почту
              </a>
            </div>
            <div className={s.self_promo_image_mail} />
          </div>
        </div>
      </div>
    );
  }
}

export class AppDating extends Component {
  render() {
    const title = 'Рамблер/знакомства – ' + config.siteTitle;
    const description =
      'Здесь можно найти настоящую любовь, завести горячий роман или просто ' +
      'пофлиртовать и хорошо провести время';
    return (
      <div className={s.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className={s.slide}>
          <SlideDating stat={{ place: 'top' }}>
            <br />
            <a
              href={'/mobile'}
              className={s.back_white}
              data-soft="product::dating::back"
            >
              <span className={s.t}>К списку приложений</span>
            </a>
          </SlideDating>
        </div>
        <div className={s.advantages}>
          {[
            {
              id: 'security',
              title: 'Удобная приватность',
              description:
                'Вход <nobr>в приложение</nobr> можно защитить <nobr>не только</nobr> паролем, но и отпечатком пальца',
            },
            {
              id: 'globe',
              title: 'Знакомства поблизости',
              description:
                'Организуйте быстрое свидание, выбрав пару из тех, кто находится <nobr>в радиусе</nobr> 1 километра от вас',
            },
            {
              id: 'chat',
              title: 'Чаты на горячие темы',
              description:
                'В анонимных чатах <nobr>по интересам</nobr> вы можете обсудить <nobr>с другими</nobr> участниками свои фантазии',
            },
            {
              id: 'todo',
              title: '20 000 000 анкет',
              description:
                'На Рамблер/знакомствах уже более 20 миллионов пользователей <nobr>со всего</nobr> мира — скучно не будет',
            },
            {
              id: 'eye',
              title: 'Наслаждайтесь вниманием',
              description:
                'Приложение Рамблер/знакомств покажет, кто смотрел вашу анкету и кому она понравилась',
            },
            {
              id: 'like',
              title: 'Никого лишнего',
              description:
                'Вас не будут донимать поклонники, если ваша симпатия не взаимна — мы заботимся о безопасности',
            },
          ].map(item => (
            <dl
              key={item.title}
              className={s.advantages_item + ' ' + s['item_' + item.id]}
            >
              <dt
                className={s.advantages_item_title}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <dd
                className={s.advantages_item_description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </dl>
          ))}
        </div>
        <div className={s.install}>
          <a
            href={
              'https://redirect.appmetrica.yandex.com/serve/818311825710907649?place_id=description'
            }
            className={s.button_apple_store}
            target="_blank"
            data-soft={'product::dating::button_2::ios'}
          />
        </div>
        <div className={s.self_promo}>
          <div className={s.self_promo_content}>
            <div className={s.self_promo_text}>
              <div className={s.self_promo_title}>
                Рамблер/знакомства —<br />
                миллионы человек<br />
                желают познакомиться
              </div>
              <div className={s.self_promo_description}>
                Здесь можно найти друга по переписке или любовь<br />
                всей жизни. Знакомьтесь, флиртуйте, встречайтесь или<br />
                просто собирайте лайки.
              </div>
              <a
                href="https://dating.rambler.ru/?utm_source=soft&utm_medium=link&utm_campaign=self_promo&utm_content=dating"
                targe="_blank"
                className={s.button}
                data-soft="product::dating::button_3"
              >
                Познакомиться
              </a>
            </div>
            <div className={s.self_promo_image_dating} />
          </div>
        </div>
      </div>
    );
  }
}

export class AppHoroscopes extends Component {
  render() {
    const title = 'Рамблер/знакомства – ' + config.siteTitle;
    const description =
      'Наш лунный и астрологический прогноз <nobr>на завтра</nobr>, неделю, год, ' +
      'а также самый полный сонник <nobr>для трактовки</nobr> ваших снов';
    return (
      <div className={s.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className={s.slide}>
          <SlideHoroscopes stat={{ place: 'top' }}>
            <br />
            <a
              href={'/mobile'}
              className={s.back_white}
              data-soft="product::horoscopes::back"
            >
              <span className={s.t}>К списку приложений</span>
            </a>
          </SlideHoroscopes>
        </div>
        <div className={s.advantages}>
          {[
            {
              id: 'solarsystem',
              title: 'Со звездами на «ты»',
              description:
                'В любой непонятной ситуации вам поможет личный гороскоп, сонник и сборник примет',
            },
            {
              id: 'person',
              title: 'Прогноз для каждого',
              description:
                'Читайте гороскоп <nobr>для любых</nobr> знаков зодиака на день, неделю, месяц <nobr>и даже год</nobr>',
            },
            {
              id: 'dreamcatcher',
              title: 'Толкование ваших снов',
              description:
                'Напишите, что вам приснилось, и получите подробное толкование <nobr>по версии</nobr> нескольких сонников',
            },
            {
              id: 'mobile',
              title: 'Гороскоп каждое утро',
              description:
                'Ежедневные Push-уведомления <nobr>с вашим</nobr> гороскопом подготовят <nobr>к грядущему</nobr> дню',
            },
            {
              id: 'moon',
              title: 'Лунный и другие гороскопы',
              description:
                'Хотите больше подробностей? Читайте Лунный и карьерный гороскоп, календарь стрижек и путешествий',
            },
            {
              id: 'chat',
              title: 'Советы астрологов',
              description:
                'В приложении вас ждут полезные советы и интересные новости из мира астрологии',
            },
          ].map(item => (
            <dl
              key={item.title}
              className={s.advantages_item + ' ' + s['item_' + item.id]}
            >
              <dt
                className={s.advantages_item_title}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <dd
                className={s.advantages_item_description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </dl>
          ))}
        </div>
        <div className={s.install}>
          <a
            href="https://redirect.appmetrica.yandex.com/serve/172250345836206603"
            className={s.button_huawei_store}
            target="_blank"
            data-soft={'product::horoscopes::button_2::huawei'}
          />
          <a
            href="https://redirect.appmetrica.yandex.com/serve/460480723044866497"
            className={s.button_ru_store}
            target="_blank"
            data-soft={'product::horoscopes::button_2::rustore'}
          />
          <span id="LinkWithModal_horoscopes2">
            <LinkWithModal
              href="https://redirect.appmetrica.yandex.com/serve/388649341109200746"
              className={s.button_google_app}
              dataSoft={'product::horoscopes::button_2::download'}
            />
          </span>
        </div>
        <div className={s.self_promo}>
          <div className={s.self_promo_content}>
            <div className={s.self_promo_text}>
              <div className={s.self_promo_title}>
                Рамблер/гороскопы —<br />
                ваш личный<br />
                астрологический гид
              </div>
              <div className={s.self_promo_description}>
                Мы поможем разобраться <nobr>во всех</nobr> превратностях<br />
                судьбы. Прогнозы, гороскопы и сонник ответят{' '}
                <nobr>на самые</nobr>
                <br />
                сложные вопросы.
              </div>
              <a
                href="https://horoscopes.rambler.ru/?utm_source=soft&utm_medium=link&utm_campaign=self_promo&utm_content=horoscopes"
                targe="_blank"
                className={s.button}
                data-soft="product::horoscopes::button_3"
              >
                Узнать судьбу
              </a>
            </div>
            <div className={s.self_promo_image_horoscopes} />
          </div>
        </div>
      </div>
    );
  }
}

export class AppAliceHoroscopes extends Component {
  render() {
    const title = 'Навык Алисы – ' + config.siteTitle;
    const description =
      'Астрологический навык Рамблер/гороскопов для устройств с Алисой от Яндекса';
    return (
      <div className={s.root}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
        </Helmet>
        <div className={s.slide}>
          <SlideAlice page>
            <br />
            <a
              href={'/voice'}
              className={s.back_white}
              data-soft="product::alice-horoscopes::back"
            >
              К списку расширений
            </a>
          </SlideAlice>
        </div>
        <div className={s.advantages}>
          {[
            {
              id: 'astrology',
              title: 'Любые гороскопы для всех',
              description:
                'Слушайте любовный и денежный прогноз, а также гороскоп на сегодня, завтра и неделю для любых знаков',
            },
            {
              id: 'chat',
              title: 'Понимаем с полуслова',
              description:
                'Общайтесь с навыком голосом и отдавайте команды в любой форме — Рамблер/гороскопы поймут вас',
            },
            {
              id: 'alice',
              title: 'Где Алиса, там мы',
              description:
                'Скажите Алисе «Запусти навык Рамблер/гороскопы» на смартфоне, в Windows или умной колонке',
            },
          ].map(item => (
            <dl
              key={item.title}
              className={s.advantages_item + ' ' + s['item_' + item.id]}
            >
              <dt
                className={s.advantages_item_title}
                dangerouslySetInnerHTML={{ __html: item.title }}
              />
              <dd
                className={s.advantages_item_description}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </dl>
          ))}
        </div>
        <div className={s.install}>
          <a
            href="https://bit.ly/alice-horoscopes-button2"
            className={sAlice.button_alice_black}
            target="_blank"
            data-soft={'product::alice-horoscopes::button_2'}
          />
        </div>
      </div>
    );
  }
}
