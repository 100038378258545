import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

export class Top100 extends Component {
  render() {
    return (
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function (w, d, c) {
            (w[c] = w[c] || []).push(function() {
                var options = {
                    project: ${this.props.id},
                    trackHashes: true,
                    protocol: 'https',
                    attributes_dataset: [
                        'cerber-topline',
                        'logo',
                        'ui',
                        'soft'
                    ]
                };
                try {
                    w.top100Counter = new top100(options);
                } catch(e) { }
            });

            var n = d.getElementsByTagName("script")[0],
                    s = d.createElement("script"),
                    f = function () { n.parentNode.insertBefore(s, n); };
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://st.top100.ru/top100/top100.js";
            if (w.opera == "[object Opera]") {
                d.addEventListener("DOMContentLoaded", f, false);
            } else { f(); }
        })(window, document, "_top100q");
        `,
          }}
        />
        <noscript>
          <img
            src={`https://counter.rambler.ru/top100.cnt?pid=${this.props.id}`}
          />
        </noscript>
      </Fragment>
    );
  }
}

Top100.propTypes = {
  id: PropTypes.number.isRequired,
};

export class GA extends Component {
  render() {
    return (
      <script
        dangerouslySetInnerHTML={{
          __html: `
          (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
            })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

            ga('create', '${this.props.id}', 'auto');
            ga('send', 'pageview');
          `,
        }}
      />
    );
  }
}

GA.propTypes = {
  id: PropTypes.string.isRequired,
};

export class Metrika extends Component {
  render() {
    return (
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
                m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
                (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
            `,
          }}
        />
        {this.props.children}
      </Fragment>
    );
  }
}

export class MetrikaCounter extends Component {
  render() {
    return (
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
                var params${this.props.id} = {
                  clickmap:true,
                  trackLinks:true,
                  accurateTrackBounce:true,
                  triggerEvent:true
                };
                ${
                  this.props.webvisor
                    ? 'params' + this.props.id + '.webvisor = true;'
                    : ''
                }
                ym(${this.props.id}, 'init', params${this.props.id});
            `,
          }}
        />
        <noscript>
          <div>
            <img
              src={`https://mc.yandex.ru/watch/${this.props.id}?ut=noindex`}
              style={{
                position: 'absolute',
                left: '-9999px',
              }}
              alt=""
            />
          </div>
        </noscript>
      </Fragment>
    );
  }
}

MetrikaCounter.propTypes = {
  id: PropTypes.number.isRequired,
  webvisor: PropTypes.bool,
};

export class NewMetrika extends Component {
  render() {
    return (
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
              m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

            ${this.props.ids
              .map(
                id => `
                  ym(${id}, "init", {
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    triggerEvent: true,
                  });
                `
              )
              .join('')}`,
            }}
        ></script>
        {this.props.ids.map(id => (
          <noscript key={id}>
            <div>
              <img
                src={`https://mc.yandex.ru/watch/${id}`}
                style={{ position: 'absolute', left: '-9999px' }}
                alt=""
              />
            </div>
          </noscript>
        ))}
      </Fragment>
    );
  }
}

export class Tns extends Component {
  render() {
    return (
      <Fragment>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              (function(win, doc, cb){
                (win[cb] = win[cb] || []).push(function() {
                    try {
                        tnsCounterRambler_ru = new TNS.TnsCounter({
                        'account':'rambler_ru',
                        'tmsec': 'rambler_soft-site'
                        });
                    } catch(e){}
                });

                var tnsscript = doc.createElement('script');
                tnsscript.type = 'text/javascript';
                tnsscript.async = true;
                tnsscript.src = ('https:' == doc.location.protocol ? 'https:' : 'http:') +
                    '//www.tns-counter.ru/tcounter.js';
                var s = doc.getElementsByTagName('script')[0];
                s.parentNode.insertBefore(tnsscript, s);
            })(window, this.document,'tnscounter_callback');
              `,
          }}
        />
        <noscript>
          <img
            src="//www.tns-counter.ru/V13a****rambler_ru/ru/UTF-8/tmsec=rambler_soft-site/"
            width="0"
            height="0"
            alt=""
          />
        </noscript>
      </Fragment>
    );
  }
}

export class LiveInternet extends Component {
  render() {
    return (
      <Fragment>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
              new Image().src = "//counter.yadro.ru/hit;rambler?r"+
              escape(document.referrer)+((typeof(screen)=="undefined")?"":
              ";s"+screen.width+"*"+screen.height+"*"+(screen.colorDepth?
              screen.colorDepth:screen.pixelDepth))+";u"+escape(document.URL)+
              ";h"+escape(document.title.substring(0,150))+
              ";"+Math.random();
            `,
          }}
        />
      </Fragment>
    );
  }
}